<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="isOpenModal"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      width="500px"
      @closed="closeModal" @open="openModal">
      <div>
        <ch-form :render-option="option" :model="modalForm" :rules=rules
                 :props="{ labelWidth: '100px' , paddingRight: '0'}"
                  ref="modalForm"
        ></ch-form>
      </div>
      <span slot="footer" class="flex_con" v-if="type === 'watch'">
        <ch-button @click="closeModal" type="cancel">关闭</ch-button>
      </span>
      <span slot="footer" class="flex_con" v-else>
        <ch-button @click="closeModal" type="cancel">取消</ch-button>
        <ch-button
          @click="handleModal('modalForm')"
          :loading="isButtonLoading"
          type="ok">确定</ch-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    getListFunction: {
      type: Function
    }
  },
  data(){
    return {
      title: '',
      modalForm: {},
      isOpenModal: false,
      isButtonLoading: false,
      type: '',
    }
  },
  computed: {
    rules: function() {
      if(this.type === 'add' || this.type === 'edit') {
        return {
          postName: [{required: true, message: "请输入岗位名称", trigger: ["blur", "change"],},],
          // postSort: [{required: true, message: "请输入数字岗位顺序", trigger: ["blur", "change"],},],
        }
      } else {
        return {}
      }
    },
    option: function () {
      if (this.type === 'watch' && this.isOpenModal) {
        return [
          {type: 'text', label: '岗位名称：', prop: 'postName'},
          {type: 'text', label: '角色人员：', prop: 'postSort'},
        ]
      }else if( this.type === 'bind') {
        return [
          {type: 'input', label: '岗位名称：', prop: 'postName', placeholder: "请输入岗位名称", props: {readonly: true}},
          {type: 'select',  label: '商户角色：', prop: 'roleId', props: {multiple: true, collapseTags: true},
            name: "roleName", value: "id", option: this.roleOption},
        ]
      }else {
        return [
          {type: 'input', label: '岗位名称：', prop: 'postName', placeholder: "请输入岗位名称"},
          {type: 'number', label: '岗位顺序：', prop: 'postSort', placeholder: "请输入岗位顺序"},
        ]
      }
    },
    ...mapGetters({
      roleOption: 'system/roleOption'
    })

  },
  mounted() {
    this.getRoleOption(false) // 获取角色下拉
  },
  methods: {
    ...mapActions({
      getPostOption: 'system/getPostOption',
      getRoleOption: 'system/getRoleOption'
    }),
    // 打开前处理
    openModal() {
      this.$nextTick(() => {
        this.$refs.modalForm?.clearValidate();
      })
    },
    openWatch(row) {
      this.title = '查看岗位'
      this.type = 'watch'
      this.modalForm = JSON.parse(JSON.stringify(row))
      this.isOpenModal = true
    },
    openAdd() {
      this.title = '新增岗位'
      this.type = 'add'
      this.modalForm = {
        postName: "",
        postSort: "",
        status: 0,
      }
      this.isOpenModal = true
    },
    openEdit(row) {
      this.title = '修改岗位'
      this.type = 'edit'
      this.modalForm = JSON.parse(JSON.stringify(row))
      this.isOpenModal = true
      delete this.modalForm.isBase
    },
    openBind(row) {
      this.type = 'bind'
      this.title = '绑定角色'
      this.modalForm = {
        ...JSON.parse(JSON.stringify(row)),
        roleId: row.roleIds || []
      }
      delete this.modalForm.isBase
      this.isOpenModal = true
    },
    // 执行 增加 或 修改
    handleModal(formRef) {
      this.$refs[formRef].validate((valid) => {
        if (valid) {
          this.isButtonLoading = true
          let parma = {...this.modalForm}
          if(this.type === 'add') {
            this.$curl.post("/hm/post/add", parma)
              .then(() => {
                this.$message.success("新增成功！");
                this.closeModal()
                this.getListFunction?.()
                this.getPostOption(true) // 刷新下拉数据
              },);
          }else if(this.type === 'edit'){
            this.$curl.post("/hm/post/edit", parma)
              .then(() => {
                this.$message.success("编辑成功！");
                this.closeModal()
                this.getListFunction?.()
                this.getPostOption(true) // 刷新下拉数据
              }).catch(() => this.isButtonLoading = false)
          } else {
            let params = {
              id:this.modalForm.id,
              children:this.modalForm.roleId
            }
            this.$curl.post("/hm/post/bindRole",params)
              .then(() => {
                this.$message.success("绑定角色成功！");
                this.closeModal()
                this.getListFunction?.()
                this.getPostOption(true) // 刷新下拉数据
              }).catch(() => this.isButtonLoading = false)
          }
        }
      });
    },
    // 关闭前清除数据
    closeModal() {
      this.modalForm = {}
      this.isOpenModal = false
      this.isButtonLoading = false
    },

  },

}
</script>